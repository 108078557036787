import {
  Row,
  Col,
  Radio,
  Modal,
  TimePicker,
  Form,
  Input,
  Select,
  InputNumber,
} from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/lang";
import moment from "moment";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const UserFrom = ({ type, path, sectionName, show, hide, data, refresh }) => {
  const api = {
    // userList: apiPath.allUserList,
    eventList: apiPath.eventByVenueId,
    editTable: apiPath.editEvent,
    addEditGuest: apiPath.addEditGuest,
    venueList: apiPath.venueList,
  };

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [eventType, setEventType] = useState();
  const [venues, setVenues] = useState([]);
  const [events, setEvents] = useState([]);
  const [users, setUser] = useState([]);

  const getVenues = () => {
    request({
      url: api.venueList,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
        }
        setVenues(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getEvents = (id) => {
    request({
      url: api.eventList + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
        }
        setEvents(data.data.data.filter((event) => event.guestListData));
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    getVenues();
    request({
      url: apiPath.userList,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
        }
        setUser(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
    if (!data) return;

    getEvents(data.venue_id?._id);
    setEventType(data.deposit);
    console.log(data, 711);
    form.setFieldsValue({
      ...data,
      country: data.country_id,
      user_id: data.user_id?.name ? data.user_id?.name : data.user_id?.email,
      venue_id: data.venue_id?.name,
      event_id: data.event_id?.name,
      check_in_time: moment(data.check_in_time),
    });

    // setCheckIn(moment(data.check_in_time))
  }, []);

  const onCreate = (values) => {
    // setLoading(true);
    console.log(values, 112);
    const payload = {
      ...values,
      deposit: eventType,
      check_in_time: values.check_in_time,
      total_guest: Number(values.male_guest) + Number(values.female_guest),
    };

    if (data) {
      payload["guest_id"] = data._id;
    }
    request({
      url: api.addEditGuest,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      width={800}
      open={show}
      title={`${
        data
          ? lang("Update") + " " + sectionName
          : lang("Add") + " " + lang("New") + " " + sectionName
      }`}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={
        <>
          <CloseOutlined style={{ marginRight: "8px" }} />
          {lang("Cancel")}
        </>
      }
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
        icon: <CheckOutlined />,
      }}
    >
      <Form
        id="create"
        form={form}
        initialValues={{}}
        onFinish={onCreate}
        layout="vertical"
      >
        <Row gutter={[24, 16]}>
          <Col span={24} lg={12} sm={12}>
            <Form.Item
              className="mb-1"
              label="Guest Mail"
              name="user_id"
              normalize={(value) => value.trimStart()}
              rules={[
                { required: true, message: "Please select user!" },
                {
                  max: 200,
                  message:
                    "Event Name should not contain more then 200 characters!",
                },
                {
                  min: 2,
                  message: "Event Name should contain at least 2 characters!",
                },
              ]}
            >
              {data ? (
                <Input disabled />
              ) : (
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch // Enable search functionality
                  filterOption={(inputValue, option) =>
                    option?.label?.toLowerCase().includes(inputValue.toLowerCase())
                  }
                  placeholder="Select Users"
                  onChange={(value) => {
                    form.setFieldValue("user_id", value);
                  }}
                  options={users?.map((v) => ({
                    label: v?.email,
                    value: v?._id,
                    name: v?.name,
                  }))}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item
              className="mb-1"
              label="Venue Name"
              name="venue_id"
              normalize={(value) => value.trimStart()}
              rules={[{ required: true, message: "Please select venue!" }]}
            >
              {data ? (
                <Input disabled />
              ) : (
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch // Enable search functionality
                  filterOption={(inputValue, option) =>
                    option.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  placeholder="Select Venue"
                  onChange={(value) => {
                    form.setFieldValue("venue_id", value);
                    getEvents(value);
                  }}
                  options={venues?.map((v) => ({
                    label: v?.name,
                    value: v?._id,
                  }))}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item
              className="mb-1"
              label="Event Name"
              name="event_id"
              normalize={(value) => value.trimStart()}
              rules={[{ required: true, message: "Please select Event!" }]}
            >
              {data ? (
                <Input disabled />
              ) : (
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select Event"
                  // onChange={(value) => form.setFieldValue("event_id", value)}
                  options={events?.map((v) => {
                    return { label: v?.name, value: v?._id };
                  })}
                  // options={[{ value: "India", label: "India" }, { value: "Canada", label: "Canada" }, { value: "USA", label: "USA" }]}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label="No. of Male Guest"
              name="male_guest"
              rules={[
                {
                  pattern: new RegExp(/^[\.0-9]*$/),
                  message: "Only Numeric Characters Allowed",
                },
              ]}
            >
              <Input
                maxLength={10}
                autoComplete="off"
                placeholder="Enter number of guest"
              />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label="No. of Female Guest"
              name="female_guest"
              rules={[
                {
                  pattern: new RegExp(/^[\.0-9]*$/),
                  message: "Only Numeric Characters Allowed",
                },
              ]}
            >
              <Input
                maxLength={10}
                autoComplete="off"
                placeholder="Enter number of guest"
              />
            </Form.Item>
          </Col>
          {/* <Col span={24} sm={12}>
            <Form.Item
              label="Total Guest"
              name="total_guest"
              rules={[
                { pattern: new RegExp(/^[\.0-9]*$/), message: "Only Numeric Characters Allowed" },
              ]}
            >
              <Input maxLength={10} autoComplete="off" placeholder="Enter number of guest" />
            </Form.Item>
          </Col> */}
          <Col span={24} sm={12}>
            <Form.Item
              label="Max Cost"
              name="max_cost"
              rules={[
                { required: true, message: "Missing maximum cost" },
                {
                  pattern: new RegExp(/^[\.0-9]*$/),
                  message: "Only Numeric Characters Allowed",
                },
              ]}
            >
              <Input
                maxLength={10}
                autoComplete="off"
                placeholder="Enter maximum cost"
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item label="Check-In Time" name="check_in_time">
              <TimePicker
                style={{ width: "100%", height: "42px", borderRadius: "6px" }}
                format="HH:mm"
              />
            </Form.Item>
          </Col>
          {/* <Col span={24} sm={12}>
            <Form.Item

              label="Deposit"
              name="deposit"

            >
              <Radio.Group defaultValue={eventType} onChange={(e) => setEventType(e.target.value)}>
                <Radio value={true}>Advanced</Radio>
                <Radio value={false}>Cash</Radio>
              </Radio.Group>            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
};

export default UserFrom;
