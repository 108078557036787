import React, { createContext, useState, useContext, useEffect } from "react";
import { LANGUAGE } from "../config/language";
import { Skeleton, Spin } from "antd";
export const AppStateContext = createContext();

const spinnerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center'
  };
  
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(0 0 0 / 16%)',
    zIndex: 9998
  };

export const AppContextProvider = ({ children }) => {

    const [language, setLanguage]    =   useState(LANGUAGE[0]['short']);
    const [loading, setLoading]      =   useState(true);

    useEffect(() => {
        let lang    =   localStorage.getItem("languageSet")
        lang        =   lang ? lang : LANGUAGE[0]['short'];

        (lang == LANGUAGE[1]['short']) ? import('../assets/styles/rtl.css') : import('../assets/styles/main.css');

        setTimeout(() => setLoading(false),200)
        
        setLanguage(lang)
    }, [])

    return (
        <AppStateContext.Provider value={{ language, setLanguage }}>
            {loading ? (
                <div style = {overlayStyle}>
                    <div style = {spinnerStyle}>
                        <Spin size = "large" />
                        {/* <p className="mt-2">Please wait ...</p> */}
                    </div>
                </div>
            ) : (
                children
            )}
        </AppStateContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppStateContext);
};