import { Row, Col, Card, Skeleton, Avatar, Table, Image, Tooltip, Button } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from 'moment';
import ConfirmationDelete from "../../components/ConfirmationDelete";

import notfound from "../../assets/images/not_found.png";
import { PRICE } from "../../constants/conts";
import lang from "../../helper/lang";
import UserFrom from "../Table/AddForm";
import AddForm from "../GuestList/AddForm";

function View() {

  const sectionName = "Event";
  const routeName = "event";

  const params = useParams();
  const { request } = useRequest();
  const { confirmDelete } = ConfirmationDelete()

  const [list, setList] = useState({});
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [visibleGuest, setVisibleGuest] = useState();
  const [tableData, setTabledata] = useState([]);
  const [ticketData, setTicketsData] = useState([]);
  const [guestData, setGuestdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.eventById + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const TableD = [
   
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return (<a className="cap avatar-text" onClick={() => navigate(`/guest-list/view/${_id}`)}>{name ? name : 'Not Available'}</a>);
      }
    },
    {
      title: `${lang("Minimum charges(in $)")}`,
      dataIndex: "min_cost",
      key: "min_cost",

    },
    {
      title: `Deposit`,
      dataIndex: "deposit",
      key: "deposit",
      render: (_, { deposit }) => {
        return (<a className="cap avatar-text">{deposit ? "Yes" : "No"}</a>);
      },
    },
    {
      title: `Reservation Fees`,
      dataIndex: "reservation_fee",
      key: "reservation_fee",
      render: (_, { reservation_fee }) => {
        return (<a className="cap avatar-text">{reservation_fee ? reservation_fee : 0}</a>);
      },
    },
    {
      title: `Table status`,
      dataIndex: "table_status",
      key: "table_status",
      render: (_, { table_status }) => {
        return (
          <a className="cap avatar-text">{table_status ? "Booked" : "Avaialable"}</a>
        );
      }
    },

    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={`${lang('Update')} ` + "Table"} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
              </Tooltip>

              <Tooltip title={`${lang('Delete')} ` + "Table"} color={"purple"} key={"delete" + routeName}>

              <Button title="Delete" 
              onClick={() => confirmDelete({ record: record._id, path: apiPath.deleteTable,
               onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>

            {/* <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip> */}

          </>
        );
      },
    },
  ];

  const Guests =  [    
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { user_id, _id }) => {
        return (<a className="cap avatar-text" >{user_id ? user_id.name : 'Not Available'}</a>);
      }
    },
    // {
    //   title: `${lang("Event Name")}`,
    //   dataIndex: "event_id",
    //   key: "event_id",
    //   render: (_, { event_id, _id }) => {
    //     return (<a className="cap avatar-text">{event_id ? event_id?.name : 'Not Available'}</a>);
    //   },
    // },
    {
      title: `${lang("Total")} ${lang("Guests")}`,
      dataIndex: "total_guest",
      key: "total_guest",
      render: (_, { total_guest }) => {
        return (<a className="cap avatar-text">{total_guest ? total_guest : 0}</a>);
      },
    },
    {
      title: `${lang("Checked")} ${lang("User")}`,
      dataIndex: "checked_user",
      key: "checked_user",
      render: (_, { checked_user}) => {
        return (<a className="cap avatar-text">{checked_user ? checked_user : 0}</a>);
      },
    },
    {
      title: `${lang("Created")} ${lang("Date")}${lang("Time")}`,
      dataIndex: "check_in_time",
      key: "check_in_time",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YY hh:mm A')
        );
      }
    },
   
    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={`${lang('Update')} ` + "GuestList"} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisibleGuest(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
              </Tooltip>
              <Tooltip title={`${lang('Delete')} ` + "GuestList"} color={"purple"} key={"update" + routeName}>

              <Button title="Delete" onClick={() => confirmDelete({ record: record._id, path: apiPath.deleteGuest, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>

            {/* <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip> */}

          </>
        );
      },
    },
  ];

  const fetchTables = (id) => {
    request({
      url: apiPath.eventTable + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setTabledata(data.data.data)
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })

  }

  const fetchGuests = (id) => {
    request({
      url: apiPath.eventGuest + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setGuestdata(data.data.data)
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  const fetchTickets = (id) => {
    request({
      url: apiPath.ticketGuests + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setTicketsData(data.data.data)
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const ticketsColumn = [
   
    {
      title: `${lang("Transaction")} ${lang("ID")}`,
      dataIndex: "transaction_id",
      key: "transaction_id",
      render: (_, { transaction_id, _id }) => {
        return (<a className="cap avatar-text" >{transaction_id ? transaction_id : "#HJHFEHDNMHT"}</a>);
      },
      sorter: (a, b) => {
        let nameA = a.transaction_id?.toLowerCase();
        let nameB = b.transaction_id?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Ticket")}`,
      dataIndex: "total_ticket",
      key: "total_ticket",
      render: (_, { total_ticket, _id }) => {
        return (<a className="cap avatar-text" 
        >{total_ticket ? total_ticket : 0}</a>);
      },
      sorter: (a, b) => {
        let nameA = a.total_ticket?.toLowerCase();
        let nameB = b.total_ticket?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Amount")}`,
      dataIndex: "amount",
      key: "price",
      render: (_, { price, _id }) => {
        return (<a className="cap avatar-text">{price ? price : ""}</a>);
      },
    },
    {
      title: `${lang("Customer")}`,
      dataIndex: "customer_id",
      key: "customer_id",
      render: (_, { customer_id }) => {
        return (<a className="cap avatar-text">{customer_id ? customer_id.name : 'Not Available'}</a>);
      },
    },
  ];
  useEffect(() => {
    fetchData(params.id)
    fetchTables(params.id)
    fetchGuests(params.id)
    fetchTickets(params.id)
  }, [refresh])

  return (
    <>
      <Row gutter={16}>
        <Col span={24} xxl={8}>
          <Card title={"Event Details"}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                {/* <div className="view-inner-cls">
                  <h5>Event Stories:</h5>
                  <h6>
                    {list && !list?.venueDetail?.image ?
                      <Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} className="cap" size={50}>
                        {list?.venueDetail?.name?.charAt(0)}
                      </Avatar>
                      :
                      <Image className="image-radius" src={list?.venueDetail?.image} />
                    }
                  </h6>
                </div> */}
                <div className="view-inner-cls">
                  <h5>{lang("Image")}:</h5>
                  <h6>
                    {list && !list?.venueDetail?.image ?
                      <Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} className="cap" size={50}>
                        {list?.venueDetail?.name?.charAt(0)}
                      </Avatar>
                      :
                      <Image className="image-radius" src={list?.venueDetail?.image} />
                    }
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Name")}:</h5>
                  <h6><span className="cap">{list?.venueDetail?.name ? list.venueDetail.name : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Venue Name:</h5>
                  <h6>{list?.venueDetail?.venue_id ? list.venueDetail.venue_id?.name : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Description:</h5>
                  <h6>{list?.venueDetail ? list.venueDetail.description : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{list.venueDetail?.recurringDate?"Event Date:": "Event Day:"}</h5>
                  <h6>{list.venueDetail?.recurringDate ? moment(list?.venueDetail?.recurringDate).format('DD-MMM-YYYY') : list?.venueDetail?.recurringDay}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Event Time:</h5>
                  <h6>{list.venueDetail?.start_time ? list.venueDetail?.end_time ? list.venueDetail?.start_time + " - " + list?.venueDetail?.end_time : list?.venueDetail?.start_time: "No Date"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Event Type:</h5>
                  <h6>{list.venueDetail?.event_type ? list.venueDetail?.event_type : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>Featured event:</h5>
                  <h6>{list?.venueDetail?.is_featured ? "Yes" : 'No'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>No. of table booked:</h5>
                  <h6>{list?.tableCount ? list?.tableCount : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>No. of guests:</h5>
                  <h6>{list?.guestCount ? list?.guestCount : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>No. of tickets sold:</h5>
                  <h6>{list?.ticketCount ? list?.ticketCount : "-"}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Active") + ' ' + lang("Status")}:</h5>
                  <h6>{list?.venueDetail?.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Registered_On")}:</h5>
                  <h6>{list?.venueDetail?.created_at ? moment(list.venueDetail.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-primary" to={`/${routeName}`}>{lang("Back")}</Link>
                </div>

              </div>
            }

          </Card>
        </Col>
        <Col span={24} xxl={16}>
        <Col span={24}  >

         {list?.venueDetail?.entry_type?.includes("Table") ? <Card title={"Event tables"} style={{marginBottom:"10px"}}>
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>

                {loading ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={TableD}
                        dataSource={tableData}
                        onChange={fetchTables}
                        className="ant-border-space"
                      />
                    </div>
                  </div>
                }
              </Col>
            </Row>
          </Card>: ""}
         { list?.venueDetail?.entry_type?.includes("Guest List")?<Card title={"Event Guest List"}  style={{marginBottom:"10px"}}>
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>

                {loading ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={Guests}
                        dataSource={guestData}
                        onChange={fetchGuests}
                        className="ant-border-space"
                      />
                    </div>
                  </div>
                }
              </Col>
            </Row>
          </Card>: ""}

          {list?.venueDetail?.entry_type?.includes("Tickets") ?<Card title={"Event Tickets Sold"}  style={{marginBottom:"10px"}}>
            <Row gutter={16}>
              <Col span={12} xs={24} md={24}>

                {loading ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={ticketsColumn}
                        dataSource={ticketData}
                        onChange={fetchTickets}
                        className="ant-border-space"
                      />
                    </div>
                  </div>
                }
              </Col>
            </Row>
          </Card>:""}
        </Col>
        <Col span={24} className="">
       {/* {list?.venueDetail?.ticketData>1?  */}
       <Card>
          <h6> Ticket Data: </h6>
        <div className="view-inner-cls">
       
                  <h5>Affiliate Link:</h5>
                  <h6>{list?.venueDetail?.ticketData?.affiliate_link }</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Promo Codes:</h5>
                  <h6>{list?.venueDetail?.ticketData?.promo_codes }</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Admin Commission:</h5>
                  <h6>{list?.venueDetail?.ticketData?.admin_commission }</h6>
                </div>
        </Card>
        {/* : ''} */}
        {/* {list?.venueDetail?.tableData>1? <Row>
          <h6> Table Data: </h6>
        <div className="view-inner-cls">
                  <h5>{lang("Registered_On")}:</h5>
                  <h6>{list?.venueDetail?.created_at ? moment(list.venueDetail.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>
        </Row>: ''}
        {list?.venueDetail?.guestListData>2? <Row>
          <h6> Guest List Data: </h6>
        <div className="view-inner-cls">
                  <h5>{lang("Registered_On")}:</h5>
                  <h6>{list?.venueDetail?.guestListData ? moment(list.venueDetail.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>
        </Row>: ''}
         */}

        </Col>
        </Col>
      </Row>
      {visible && <UserFrom type={sectionName} path={apiPath.addEditTable} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {visibleGuest && <AddForm type={sectionName}  sectionName={sectionName} show={visibleGuest} hide={() => { setSelected(); setVisibleGuest(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}


export default View;
