import { Row, Col, Radio, Modal, Image, Button, Form, Input, Select, InputNumber } from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import useApi from "../../hooks/useApi";
import MultipleImageUpload from "../../components/MultipleImageUpload";
const Option = Select;

const UserFrom = ({ type, path, sectionName, show, hide, data, refresh }) => {
  const api = {
    // userList: apiPath.allUserList,
    editEvent: apiPath.editPhotos,
    addPhotos: apiPath.addPhotos,
    venueList: apiPath.venueList
  }

  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif", "video/mp4"];
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [accessType, setAccessType] = useState();

  const [events, setEvents] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState();

  const [venues, setVenues] = useState([]);

  const getEvents = () => {
    request({
      url: api.venueList,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          console.log(data.data, 3222)
        }
        setVenues(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }
  const getVenues = () => {
    request({
      url: apiPath.eventListing+`/${selectedVenue}`,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          console.log(data.data, 3222)
        }
        setEvents(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }
  const handleVenueImage = (data) => {
    if(!data.length) return

    console.log(data, "data>>>>")
    setImage(data.map(item=> item.status == "done"? item.url:""));
    // setImage(data[0]?.url);
  }

  useEffect(() => {
    getVenues()
  }, [selectedVenue])

  useEffect(() => {
    getEvents();
    if (!data) return;
    form.setFieldsValue({
      ...data,
      country: data.country_id,
      venue_id: data.venue_id?.name,
      event_id: data.event_id?.name
    });
    setSelectedVenue(data.venue_id._id);
    console.log(data.floor_img, "Image>>>>>.")
    setImage([data.image]);

  }, [])




  const onCreate = (values) => {
    // setLoading(true);

    const payload = {
      ...values,
      type: "public",
      image: image?.length ? image : '',
    };
    request({
      url: `${data ? api.editEvent + "/" + data._id : api.addPhotos}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };




  return (
    <Modal
      width={800}
      open={show}
      title={`${data ? lang("Update") + " " + sectionName : lang("Add") + " " + lang("New") + " " + sectionName}`}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={
        <>
          <CloseOutlined style={{ marginRight: '8px' }} />
          {lang("Cancel")}
        </>
      }
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
        icon: < CheckOutlined />,
      }}
    >
      <Form id="create" form={form} initialValues={{}} onFinish={onCreate} layout="vertical">
        <Row gutter={[24, 16]}>
          

          {data ?<Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Venue Name"
              name="venue_id"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select Venue!" },
              ]}
            >
               <Input disabled/>
            </Form.Item>
           
          </Col>:<Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Venue Name"
              name="venue_id"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select Venue!" },
              ]}
            >
              
              <Select
                style={{
                  width: '100%',
                }}
                placeholder="Select Venue"
                onChange={(value) => setSelectedVenue( value)}
                options={venues?.map(v => {
                  return { label: v?.name, value: v?._id}
                })}
              // options={[{ value: "India", label: "India" }, { value: "Canada", label: "Canada" }, { value: "USA", label: "USA" }]}
              />
            </Form.Item>
          </Col>}
          {data ?"":<Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Access Type"
              name="image_type"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select access type!" },
              ]}
            >
              
              <Select
                style={{
                  width: '100%',
                }}
                placeholder="Select Access Type"
                onChange={(value) => setAccessType(value)}
                options={[{ value: "gallery", label: "Gallery" }, { value: "story", label: "Story" }]}
              />
            </Form.Item>
          </Col>}

         { accessType == "story" || data?.image_type == "story" ?

data ?<Col span={24} lg={12} sm={12}>
<Form.Item className="mb-1"
  label="Event Name"
  name="event_id"
  normalize={value => value.trimStart()}
  rules={[
    { required: true, message: "Please select Event!" },
  ]}
>
<Input  disabled /></Form.Item>

</Col>:
         <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Event Name"
              name="event_id"
              normalize={value => value.trimStart()}
              // rules={[
              //   { required: true, message: "Please select Event!" },
              // ]}
            >
              
              <Select
                style={{
                  width: '100%',
                }}
                placeholder="Select Event"
                // onChange={(value) => form.setFieldValue("venue_id", value)}
                options={events?.map(v => {
                  return { label: v?.name, value: v?._id}
                })}
              // options={[{ value: "India", label: "India" }, { value: "Canada", label: "Canada" }, { value: "USA", label: "USA" }]}
              />
            </Form.Item>
          </Col>: ""}
         
         { !data || data?.image_type=="gallery"?
         <Col span={12}>
            <Form.Item label={"Upload Photos (Width : 1130 x Height : 640)"} name="image"
             >
                {
                  !data?
                //   <MultipleImageUpload
                //   disabled={!isEdit}
                //   value={image}
                //   data={image}
                //   fileType={FileType}
                //   imageType={"category"}
                //   btnName={lang("Image")}
                //   onChange={(data) => handleImage(data)}
                //   isDimension={true}
                // />
                  <MultipleImageUpload data={image} value={image} fileType={FileType} imageType={'Gallary'} btnName={'Gallary'} onChange={handleVenueImage} />
                  :
                  <SingleImageUpload value={image} fileType={FileType} imageType={'Gallary'} btnName={'Gallary'} onChange={handleVenueImage} ></SingleImageUpload>
                }
              
            </Form.Item>

            {image && image.length > 0 &&
              <div className="mt-2">
                {console.log(image, 999)}
                {image.map((image)=> image?.split(".").pop() === "mp4" ? (
      <video width={200} height={100} src={image !== "" ? image : notfound} controls />
    ) : (
      <Image width={100} src={image !== "" ? image : notfound} />
    )
  )}              </div>
            } 
          </Col>:
           <Col span={12}>
           <Form.Item label={"Upload Story (Width : 1130 x Height : 640)"} name="image"
            >
               {
                 !data?<MultipleImageUpload value={image} fileType={FileType} imageType={'Gallary'} btnName={'Story'} onChange={handleVenueImage} />:
                 <SingleImageUpload value={image} fileType={FileType} imageType={'Gallary'} btnName={'Story'} onChange={handleVenueImage} ></SingleImageUpload>
               }
             
           </Form.Item>
           {console.log(image, 23444)}

           {image && image.length > 0 &&
  <div className="mt-2">
    {image.map((image)=> image?.split(".").pop() === "mp4" ? (
      <video width={200} height={100} src={image !== "" ? image : notfound} controls />
    ) : (
      <Image width={100} src={image !== "" ? image : notfound} />
    )
  )}
  </div>
}

         </Col>}
        </Row>
      </Form>
    </Modal >
  )
};

export default UserFrom;