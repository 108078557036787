import React, { useEffect,useState } from "react";
import Main from "../components/layout/Main";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";

const { Search } = Input;

function Terms() {
  const navigate = useNavigate();
const {request} = useRequest()
const [list,setList] =useState()

  useEffect(()=>{
    request({
        url:'/common/get-content/privacy-policy',
        method: 'GET',
        onSuccess: (data) => {
            // console.log(data.data)
          setList(data.data)
        },
        onError: (error) => {
          console.log(error)

          ShowToast(error, Severty.ERROR)
        }
      })
  },[])
  return (
 
      <section className="home-wrap px-lg-4 px-3 pb-lg-4 pb-3 container-fluid">
        <div className="row g-3">
          <div className="col-lg-12">
            <div className="pagreHeader">
              <div className="pageHeadign d-flex align-items-center gap-3 mb-3">
                <button
                  onClick={() => navigate("/sign-up")}
                  className="btn backBtn bg-transparent border-0 shadow-none p-0 d-flex"
                >
                  {/* <img className="h-100" src={backArrow} alt="Back Arrow" /> */}
                </button>
                <div className="txt">
              <p dangerouslySetInnerHTML={{__html: list.en_description }}></p> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
  );
}

export default Terms;