import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Checkbox,Tooltip, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import AddForm from "../News/AddForm";
import ImportForm from "../../components/ImportForm";
import ConfirmationBox from "../../components/ConfirmationBox";
import ConfirmationDelete from "../../components/ConfirmationDelete";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import lang from "../../helper/lang";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
  const permission= userProfile.user_type== "SubAdmin"?userProfile.permission.NewsSection:undefined;

  const sectionName = lang("News");
  const routeName = "news";

  const api = {
    status: apiPath.statusNews,
    delete: apiPath.deleteNews,
    addEdit: apiPath.addEditNews,
    list: apiPath.listNews,
    importFile: apiPath.importNews
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
//   const { confirmDelete } = ConfirmationDelete()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const columns = [
    {
      title: `${lang("News Image")}`,
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => {
        return <Image className="image-index-radius" src={image} />
      },
    },
    {
      title: `${lang("News Title")}`,
      dataIndex: "title",
      key: "title",
      render: (_, { title, _id }) => {
        return (<a className="cap avatar-text" >{title ? title : 'Not Available'}</a>);
      },
    
    },
    {
      title: `${lang("News Description")}`,
      dataIndex: "description",
      key: "description",
      render: (_, { description, _id }) => {
        return (<a className="cap avatar-text" >{description ? description : 'Not Available'}</a>);
      },
    },
    {
      title: `${lang("Auto Delete")}`,
      dataIndex: "autoDelete",
      key: "autoDelete",
      render: (_, { autoDelete, deleteDate }) => {
        return (<a className="cap avatar-text">{autoDelete ? <p>Yes, Date: {moment(deleteDate).format('DD-MMM-YYYY')} </p> : '-'}</a>);
      }, 
    },
    {
      title: `${lang("Auto Deactivate")}`,
      dataIndex: "autoDeactivate",
      key: "autoDeactivate",
      render: (_, { autoDeactivate, deactivateDate }) => {
        return (<a className="cap avatar-text">{autoDeactivate?<p>Yes, Date: {moment(deactivateDate).format('DD-MMM-YYYY')} </p> : '-'}</a>);
      },
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<a><Tag onClick={(e) => {if(userProfile.user_type=="Admin" || 
        permission?.includes("edit")){          
          showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev), type })
      }}}
      color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      }
    },
    ...(userProfile.user_type=="Admin" ||permission?.includes("view") ? [ {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
                     { userProfile.user_type=="Admin" ||permission?.includes("edit") ? 

            <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
              </Tooltip>:""}

            {/* <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip> */}

          </>
        );
      },
    },]:[])
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    const filterVerify = filters ? filters.is_verify : null;

    request({
      url: api.list + `?verify=${filterVerify ? filterVerify.join(',') : ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
      setPagination({ current: 1 })
    } else {
      setStartDate()
      setEndDate()
      setPagination({ current: 1 })
    }
  };
  const excelData = list.map(row => ({
    "News Title": row.title ? row.title : '-',
    "News Description": row.description ? row.description : '-',
    "Auto Delete": row.autoDelete ? `Yes, Date: ${moment(row.deleteDate).format('DD-MMM-YYYY')}` : '-',
    "Auto Deactivate": row.autoDeactivate ?`Yes, Date: ${moment(row.deactivateDate).format('DD-MMM-YYYY')}` : '-',
   "Status": row.is_active ? 'Active' : 'Inactive',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));

  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} ${lang("News")} ${lang("Title")}`}
                    />
                    <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
                  </div>
                  <div className="button_group">
                  {userProfile.user_type=="Admin" || 
        permission?.includes("add")?<Button onClick={(e) => { setVisible(true); setSearchText(''); }}>{lang("Add")} {sectionName}</Button>
                     :""}
                    <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>

                     </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ 
                    ...pagination,
                    defaultPageSize: 10,
                     responsive: true, 
                    //  total: pagination.total, 
                     showSizeChanger: true, 
                     pageSizeOptions: ['10', '20', '30', '50'] 
                    }}             
                        onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddForm type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}
export default Index;
