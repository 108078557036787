import { Row, Col, Card, Skeleton, Avatar, Image, Modal} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import lang from "../../helper/lang";

function TableView({title, path, show, hide, refresh}) {

  const sectionName = "Event";
  const routeName = "event";

  const params = useParams();
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = (Id) => {
    setHovered(true);

    console.log('Hovered');
    // Add any additional logic here
  };

  const handleMouseLeave = () => {
    setHovered(false);
    console.log('Not hovered');
    // Add any additional logic here
  };
  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.eventTable + "/" + path,
      method: 'GET',
      onSuccess: ({data}) => {
        setLoading(false);
        console.log(data.data, 299)
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const fetchGuestList = (id) => {
    setLoading(true);
    request({
      url: apiPath.eventGuest + "/" + path,
      method: 'GET',
      onSuccess: ({data}) => {
        setLoading(false);
        console.log(data.data, 299)
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id)
  }, [])

  return (
    <>
     <Modal
      width={800}
      open={show}
      title={title}
      // okText={`${data ? lang("Update") : lang("Add")}`}
      // cancelText={
      //   <>
      //     <CloseOutlined style={{ marginRight: '8px' }} />
      //     {lang("Cancel")}
      //   </>
      // }
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
        icon: < CheckOutlined />,
      }}
    >
      <Row gutter={16}>
        <Col span={24} xs={24}>
          <Card title={"Tables"}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                {list?.map((table) => 
                <div className="view-inner-cls">
                 <p onMouseEnter={() => handleMouseEnter(table._id)}
        onMouseLeave={handleMouseLeave}>{table.name}</p>
                </div>
                )}
              
              </div>
            }

          </Card>
        </Col>
      </Row>
      </Modal>
      {
        hovered && <Modal>
          <Card title={"Tables"}>

{loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
  <div className="view-main-list">
    <p>guest name</p>
    <p>total members</p>
    <p>checkin</p>
    <p>arrival time</p>
    <p>table amount</p>

  
  </div>
}

</Card>
        </Modal>
      }
    </>
  );
}


export default TableView;
