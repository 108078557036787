import { Row, Col, Card, Table, Button,Form, Input, Tag, DatePicker, Tooltip, Avatar, Image, Modal } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import ShowTotal from "../../../components/ShowTotal";
import { ShowToast, Severty } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import apiPath from "../../../constants/apiPath";
import UserFrom from "../UserFrom";
import ImportForm from "../../../components/ImportForm";
import ConfirmationBox from "../../../components/ConfirmationBox";
import { DownloadExcel, SampleFileDownload } from "../../../components/ExcelFile";
import ConfirmationDelete from "../../../components/ConfirmationDelete";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import lang from "../../../helper/lang";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
  const permission= userProfile.user_type== "SubAdmin"?userProfile.permission.VendorSection:undefined;

  const sectionName = lang("Vendor");
  const routeName = "vendor";

  const api = {
    status: apiPath.statusVendor,
    delete: apiPath.deleteVendor,
    addEdit: apiPath.addEditVendor,
    list: apiPath.listVendor,
    changePassword: apiPath.vendorPassword,
    importFile: apiPath.importVendor
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  // const { confirmDelete } = ConfirmationDelete();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [passModal, setPassModal] = useState(false);
  const [vendorIds, setVendorIds] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [permissions, setPermissions] = useState();

  const [btn, setBtn] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const view = (id) => {
    navigate(`/user/${routeName}/view/${id}`)
  }

  const activity = (id) => {
    navigate(`/user/activity/${id}`)
  }

  const columns = [
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id, image }) => {
        return (
          <>
            {!image ? (
              <>
               <div className="main-table-div8888">
                <Avatar className="cap" size={40}>{name?.charAt(0)}</Avatar>
                <a className="cap avatar-text customer-cap" >{name}</a>
                </div>
              </>
            ) : (
              <>
                <Image className="image-index-radius" src={image} />
                <a className="cap avatar-text customer-cap" >{name}</a>
              </>
            )}
          </>
        );
      },
     
    },
    // {
    //   title: `${lang("Unique_ID")}`,
    //   dataIndex: "unique_id",
    //   key: "unique_id",
    //   render: (_, { unique_id }) => {
    //     return unique_id ? unique_id : '-';
    //   },
    //   sorter: (a, b) => {
    //     let nameA = (a.unique_id) || "";
    //     let nameB = (b.unique_id) || "";
    //     return nameA.localeCompare(nameB);
    //   },
    // },
    {
      title: `${lang("Email")} ${lang("Address")}`,
      dataIndex: "email",
      key: "email",
      width: 210,
      render: (_, { email }) => {
        return email ? email : '-';
      },
     
    },
    {
      title: `${lang("Mobile")} ${lang("Number")}`,
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+1") + (mobile_number ? mobile_number : '')
        );
      },
    },
    {
      title: `${lang("Business")} ${lang("Name")}`,
      dataIndex: "business_name",
      key: "business_name",
      render: (_, { business_name }) => {
        return business_name ? business_name : '-';
      },
      
    },
    {
      title: `${lang("Verified")}`,
      key: "is_verify",
      filters: [
        {
          text: 'Verified',
          value: true,
        },
        {
          text: 'Not Verified',
          value: false,
        },
      ],
      render: (_, { is_verify, _id }) => {
        let color = is_verify ? 'cyan' : 'red';
        const type = "is_verify";
        return (
          <a>
            <Tag 
              onClick={(e) => {
                if(userProfile.user_type=="Admin" || 
        permission.includes("edit")){
              { if(!is_verify){
                showConfirm({ 
                  record: _id, 
                  path: api.status,
                 onLoading: () => setLoading(true),
                  onSuccess: () => setRefresh(prev => !prev), type })
               }}
            } }}

              color={color} key={is_verify}>
                 {is_verify ? "Verified" : "Not Verified"} 
                 </Tag>
          </a>
        );
      },
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<a><Tag onClick={(e) => 
{      if(userProfile.user_type=="Admin" || 
permission.includes("edit")){
      showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev), type })
}  }     } color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
     
    },
    ...(userProfile.user_type=="Admin" ||permission.includes("view") ? [ 
      {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
           { userProfile.user_type === "Admin" || permission.includes("edit")?
           <>
    <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>
            <Tooltip title={"Change Password"} color={"purple"} key={"update" + routeName}>
            <Button title="Change Password" onClick={() => {
              setSelected(record._id);
              setPassModal(true)
            }}>
              <i className="fa fa-light fa-key"></i>
            </Button>
          </Tooltip></>
            :""}

            <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>

          {userProfile.user_type=="Admin"  ?  <Tooltip title={`${lang('Activity_Log')}`} color={"purple"} key={"activity" + routeName}>
              <Button onClick={(e) => activity(record._id)}>
                <i className="fas fa-light fa-history"></i>
              </Button>
            </Tooltip>:""}
          </>
        );
      },
    }]:[])
  ];
  const getPermissions = () => {
    request({
      url: apiPath.profile,
      method: 'GET',
      onSuccess: (data) => {
        setPermissions(data.data.permission);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  useEffect(() => {
    setLoading(true)
    getPermissions();

    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const filterVerify = filters ? filters.is_verify : null;
    request({
      url: api.list + `?verify=${filterVerify ? filterVerify.join(',') : ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setBtn(data.data.btn);
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
        setVendorIds(() => {
          let data1 = data.data.list.docs?.map((elem) => elem?._id)
          return data1 || []
        })
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
      setPagination({ current: 1 })

    } else {
      setStartDate()
      setEndDate()
      setPagination({ current: 1 })

    }
  };

  const handleChangePassword=(value)=> {
    const payload = {
      id: selected,
      password: value.new_password
    }
    request({
      url: api.changePassword,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        ShowToast("Password changed", Severty.SUCCESS)
        setPassModal(false)
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  const excelData = list.map(row => ({
    "Name": row.name ? row.name : '-',
    "Email": row.email ? row.email : '-',
    "Mobile Number": row.mobile_number ? row.country_code+" "+row.mobile_number : '-',
    "Business Name": row.business_name ? row.business_name : '-',
    "Verified": row.is_verify ? "Verfied" : 'Not Verified',
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));

  return (
    <>
      <div className="tabled specialistMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search
                      allowClear
                      size="large"
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} ${lang("Name")}, ${lang("Mobile")}, ${lang("Email")}, ${lang("Unique_ID")}`}
                    />
                    <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
                  </div>

                  <div className="button_group">
                    <Button onClick={() => showConfirm({ record: list, path: apiPath.statusVendorAll, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev), type: "all" })}>{lang(btn ? "Deactivate All" : "Activate All")} {sectionName}s</Button>
{userProfile.user_type=="Admin" ||permission.includes("add") ?
             <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>{lang("Add")} {sectionName} </Button>
:""}                 
   <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>
                    <Button title="Import" onClick={(e) => { setImportVisible(true); setSearchText(''); }}><i className="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Import</Button>
                  </div>
                </>
              }
            >
              {console.log(passModal, 3655)}
              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ 
                    ...pagination,
                    defaultPageSize: 10,
                     responsive: true, 
                    //  total: pagination.total, 
                     showSizeChanger: true, 
                     pageSizeOptions: ['10', '20', '30', '50'] 
                    }}          
                           onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <UserFrom type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}
      {
        passModal && 
        <Modal
        width={1100}
        open={passModal}
        title={"Reset Password"}
        okText={"Change Password"}
        cancelText={
          "Cancel"
        }
        onCancel={() => setPassModal(false)}
        okButtonProps={{
          form: 'create',
          htmlType: 'submit',
          loading: loading,
          disabled: permissions && !permissions?.CustomerSection?.includes('add'),
          title: !permissions?.CustomerSection?.includes('add') ? 'Insufficient Permission' : '',
          icon: < CheckOutlined />,
        }}
        >
         <Form id= "create" form={form} onFinish={handleChangePassword} layout="vertical">
         <Form.Item
            label="New Password"
            name="new_password"
            hasFeedback
            rules={[
              { required: true, message: "Please enter the new password!" }
            ]}
          >
            <Input.Password placeholder="Enter new password..."/>
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_new_password"
            dependencies={['new_password']}
            hasFeedback
            rules={[
              { required: true, message: "Please enter the confirm password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password  placeholder="Re-enter new password..."/>
          </Form.Item>
         </Form>

        </Modal>
      }
    </>
  );
}

export default Index;
