import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Checkbox,Tooltip, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import AddForm from "../Discount/AddForm";
import ImportForm from "../../components/ImportForm";
import ConfirmationDelete from "../../components/ConfirmationDelete";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";

import ConfirmationBox from "../../components/ConfirmationBox";
import lang from "../../helper/lang";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
  const permission= userProfile.user_type== "SubAdmin"?userProfile.permission.DiscountSection:undefined;

  const sectionName = lang("Discounts");
  const routeName = "discount";

  const api = {
    status: apiPath.statusDiscount,
    delete: apiPath.deleteDiscount,
    addEdit: apiPath.addEditDiscount,
    list: apiPath.listDiscount,
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const { confirmDelete } = ConfirmationDelete()

  const [singleConfirm, setSingleConfirm] = useState(false);
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const columns = [
    {
      title: `${lang("Discount Code")}`,
      dataIndex: "discountCode",
      key: "discountCode",
      render: (_, { discountCode, _id }) => {
        return (<a className="cap avatar-text" >{discountCode ? discountCode : '-'}</a>);
      },
    
    },
    {
      title: `${lang("Discount Percentage")}`,
      dataIndex: "discountPercentage",
      key: "discountPercentage",
      render: (_, { discountPercentage, _id }) => {
        return (<a className="cap avatar-text" >{discountPercentage ? discountPercentage : '-'}</a>);
      },
    },
    {
      title: `${lang("No. of User")}`,
      dataIndex: "noOfUser",
      key: "noOfUser",
      render: (_, { noOfUser }) => {
        return (<a className="cap avatar-text">{noOfUser ?noOfUser : '-'}</a>);
      }, 
    },
    {
      title: `${lang("Ticketed Events")}`,
      dataIndex: "eventTickets",
      key: "eventTickets",
      render: (_, { events }) => {
        return (<a className="cap avatar-text">{events ?events.name : '-'}</a>);
      }, 
    },
    {
      title: `${lang("Coupon started on")}`,
      dataIndex: "startDate",
      key: "startDate",
      render: (_, { startDate }) => {
        return (<a className="cap avatar-text">{startDate?moment(startDate).format("YYYY-MM-DD"): '-'}</a>);
      },
    },
    {
      title: `${lang("Coupon ends on")}`,
      dataIndex: "endDate",
      key: "endDate",
      render: (_, { endDate }) => {
        return (<a className="cap avatar-text">{endDate?moment(endDate).format("YYYY-MM-DD") : '-'}</a>);
      },
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<a><Tag onClick={(e) => {if(userProfile.user_type=="Admin" || 
        permission?.includes("edit")){          
          showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev), type })
      }}}
      color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    ...(userProfile.user_type=="Admin" ||permission?.includes("edit") ? [ {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>

            <Tooltip title={`${lang('Delete')} ` + sectionName} color={"purple"} key={"delete" + routeName}>
              <Button title="Delete" onClick={() => confirmDelete({ record: record._id, path: api.delete, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) ,
              title: `Are you sure want to delete ${record.discountCode} code ?`
              })}>
                <i className="fa fa-light fa-trash"></i>
              </Button>
            </Tooltip>

          </>
        );
      },
    },]:[])
  ];

  const handleDelete = (id) => {
    console.log(id, "hbcdasj")
    setSingleConfirm(true);
    setSelected(id);
   
  };

  const deleteDiscount = () => {
    request({
      url: api.delete ,
      method: "POST",
      onSuccess: (data) => {
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData({...pagination,current:1, pageSize:10})
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    const filterVerify = filters ? filters.is_verify : null;

    request({
      url: api.list + `?verify=${filterVerify ? filterVerify.join(',') : ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1, pageSize:10 })
  };

  const excelData = list.map(row => ({
    "Discount Code": row.discountCode ? row.discountCode : '-',
    "Discount Percentage": row.discountPercentage ? row.discountPercentage : '-',
    "No. of User": row.noOfUser ? row.noOfUser : '-',
    "Ticketed Events": row.events ? row.events.name : '-',
    "Coupon started on": row.startDate ? moment(row.startDate).format("YYYY-MM-DD") : '-',
    "Coupon ends on": row.endDate ? moment(row.endDate).format("YYYY-MM-DD") : '-',
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));

  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} ${lang("Discount")} ${lang("Code")}`}
                    />
                    {/* <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} /> */}
                  </div>
                  <div className="button_group">
                    <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>{lang("Add")} {sectionName}</Button>
                    <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>

                     </div>
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ 
                    ...pagination,
                    defaultPageSize: 10,
                     responsive: true, 
                    //  total: pagination.total, 
                     showSizeChanger: true, 
                     pageSizeOptions: ['10', '20', '30', '50'] 
                    }}  
                    onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddForm type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}
      {/* {singleConfirm && (
        <confirmDelete
          title={"Delete Notification"}
          subtitle={`Are you sure you want to delete this discount?`}
          show={singleConfirm}
          hide={() => {
            setSingleConfirm(false);
            setSelected();
          }}
          path = {api.delete}
          data={`${selected}`}
          refresh={() => setRefresh(prev => !prev)}        />
      )} */}
    </>
  );
}
export default Index;
