import { Row, Col, Card, Button, Skeleton, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from 'moment';
import lang from "../../helper/lang";

function View() {

  const sectionName = lang("Sub_Admin");
  const routeName   = "sub-admin";

  const params                =   useParams();
  const { request }           =   useRequest();
  const [list, setList]       =   useState({});
  const [loading, setLoading] =   useState(false);

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewSubAdmin + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id)
  }, [])

  return (
    <>
      <Row gutter={16}>
        <Col span={12} xs={24}>
          <Card title={sectionName + ` ${lang("Detail")}`}>

            {loading ? [1,2,3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>{lang("Image")}:</h5>
                  <h6> 
                    { list && list.name && !list.image ? 
                      <Avatar style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} className="cap" size={50}> {list.name.charAt(0)} </Avatar> : 
                      <Image className="image-radius" src={list.image} /> 
                    }
                  </h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Name")}:</h5>
                  <h6><span className="cap">{list.name ? list.name : '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Email") + ' ' + lang("Address")}:</h5>
                  <h6>{list.email}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Mobile") + ' ' + lang("Number")}:</h5>
                  <h6>{list ? '+' + list.country_code + '-' : '+965'}{list ? list.mobile_number : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Status")}:</h5>
                  <h6>{list.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="InActive" />}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Registered_On")}:</h5>
                  <h6>{list.created_at ? moment(list.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>

                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-primary"  to={`/${routeName}`}>{lang("Back")}</Link>
                </div>

              </div>
            }

          </Card>
        </Col>
      </Row>
    </>
  );
}


export default View;
