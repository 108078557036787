const shortLang = {
    en: "En",
    ar: "ar",
}

const longLang = {
    en: "English",
    ar: "Arabic",
}

const LANGUAGE = [
    { short: 'en', long: "English" },
    { short: 'ar', long: "Arabic" },
];

export { shortLang, longLang, LANGUAGE };