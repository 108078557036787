import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../../components/ShowTotal";
import apiPath from "../../../constants/apiPath";
import UserFrom from "../UserFrom";
import ImportForm from "../../../components/ImportForm";
import ConfirmationBox from "../../../components/ConfirmationBox";
import ConfirmationDelete from "../../../components/ConfirmationDelete";
import { DownloadExcel, SampleFileDownload } from "../../../components/ExcelFile";
import lang from "../../../helper/lang";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
  const permission= userProfile.user_type== "SubAdmin"?userProfile.permission.CustomerSection
  :undefined;

  const sectionName = lang("Customer");
  const routeName = "customer";

  const api = {
    status: apiPath.statusCustomer,
    delete: apiPath.deleteCustomer,
    addEdit: apiPath.addEditCustomer,
    list: apiPath.listCustomer,
    importFile: apiPath.importCustomer
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/user/${routeName}/view/${id}`)
  }

  const activity = (id) => {
    navigate(`/user/activity/${id}`)
  }

  const columns = [
    {
      title: `${lang("Unique")} ${lang("ID")}`,
      dataIndex: "unique_id",
      key: "unique_id",
      render: (_, { unique_id }) => {
        return unique_id ? unique_id : '-';
      },
      
    },
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id, image }) => {
        return (
          <div>
            {!image ? (
              <>
               <div className="main-table-div8888">
                <Avatar className="cap" size={40}>{name ? name.charAt(0) : 'Not Available'}</Avatar>
                <a className="cap avatar-text customer-cap" >{name ? name : 'Not Available'}</a>
                </div>
              </>
            ) : (
              <>
                <Image className="image-index-radius" src={ image} />
                <a className="cap avatar-text customer-cap" >{name ? name : 'Not Available'}</a>
              </>
            )}
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Mobile")} ${lang("Number")}`,
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+1") + (mobile_number ? mobile_number : '')
        );
      }
    },
    {
      title: `${lang("Email")} ${lang("Address")}`,
      dataIndex: "email",
      key: "email",
      width: 210,
      render: (_, { email }) => {
        return email ? email : '-';
      },
      sorter: (a, b) => {
        let nameA = a.email;
        let nameB = b.email;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Gender")}`,
      key: "Gender",
      filters: [
        {
          text: 'Male',
          value: 'Male',
        },
        {
          text: 'Female',
          value: 'Female',
        },
      ],
      render: (_, { gender }) => {
        return (
          <span >{ gender || '-'}</span>
        );
      },
    },
    {
      title: `${lang("Events")} ${lang("Joined")}`,
      dataIndex: "events",
      key: "events",
      width: 210,
      render: (_, { events }) => {
        return  events.length ;
      },
    
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev), type })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: `${lang("Deleted")}`,
      key: "is_delete",
      filters: [
        {
          text: 'Deleted',
          value: true,
        },
        {
          text: 'Live',
          value: false,
        },
      ],
      render: (_, { is_delete, _id }) => {
        let color = is_delete ? 'brown' : 'orange';
        const type = "is_delete";
        return (<a><Tag color={color} key={is_delete} >{is_delete ? "Deleted" : "Live"}</Tag></a>);
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      }
    },
    ...(userProfile.user_type=="Admin" ||permission?.includes("view") ? [ {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
           { userProfile.user_type=="Admin" ||permission?.includes("edit") ? 
 <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
              <Button title="Edit" onClick={() => {
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button>
            </Tooltip>:""
}
            <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={(e) => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>

           {userProfile.user_type=="Admin" ?
 <Tooltip title={`${lang('Activity_Log')}`} color={"purple"} key={"activity" + routeName}>
              <Button onClick={(e) => activity(record._id)}>
                <i className="fas fa-light fa-history"></i>
              </Button>
            </Tooltip>:''}

          </>
        );
      },
    }]:[]),
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const filterDelete = filters? filters.is_delete: null;
    const filterVerify = filters ? filters.is_verify : null;
    const genderFilter = filters? filters.Gender : null;

    request({
      url: api.list + `?verify=${filterVerify ? filterVerify.join(',') : ''}&gender=${genderFilter? genderFilter.join(','): ''}&status=${filterActive ? filterActive.join(',') : ''}&delete=${filterDelete ? filterDelete.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
      setPagination({ current: 1 })

    } else {
      setStartDate()
      setEndDate()
      setPagination({ current: 1 })

    }
  };
  const excelData = list.map(row => ({
    "Unique Id": row.unique_id ? row.unique_id : '-',

    "Name": row.name ? row.name : '-',
    "Email": row.email ? row.email : '-',
    "Mobile Number": row.mobile_number ? row.country_code+ " "+ row.mobile_number : '-',
    "Gender": row.gender ? row.gender : '-',
    "Joined Events": row.events.length ,
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Location": row.location ? row.location : '-',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));
  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} ${lang("Name")}, ${lang("Mobile")}, ${lang("Email")}, ${lang("Unique_ID")}`}
                    />
                    <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
                  </div>
                 {userProfile.user_type=="Admin" || 
        permission?.includes("add")? <div className="button_group">
                    <Button onClick={(e) => { setVisible(true); setSearchText(''); }}>{lang("Add")} {sectionName}</Button>
                    <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>
                    {/* <Button title="Import" onClick={(e) => { setImportVisible(true); setSearchText(''); }}><i className="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Import</Button> */}
                  </div>:""}
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ 
                    ...pagination,
                    defaultPageSize: 10,
                     responsive: true, 
                    //  total: pagination.total, 
                     showSizeChanger: true, 
                     pageSizeOptions: ['10', '20', '30', '50'] 
                    }} 
                                    onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <UserFrom type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}

      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}
export default Index;
