import { Row, Col, Radio, Modal, Image, Button, Form, Input, Select, Checkbox, InputNumber, Calendar } from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
// import LocationMap from "./LocationMap";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { DatePicker } from 'antd';
import moment from "moment";

import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
const AddForm = ({ type, path, sectionName, show, hide, data, refresh }) => {

  const api = {
    userList: apiPath.allUserList,
  }

  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"];
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [events, setEvents] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState()
  const [autoDelete, setAutoDelete] = useState(false);
  const [autoDeactivate, setAutoDeactivate] = useState(false);

  const onCheckboxChange = (values) => {
    setAutoDeactivate(values.autoDeactivate);
    setAutoDelete(values.autoDelete);
  };
  const getVenues = () => {
    request({
      url: apiPath.discountEvents,
      method: "GET",
      onSuccess: (data) => {
        setEvents(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }

  const handleVenueImage = (data) => {
    setImage(data[0]?.url);
  }

  useEffect(() => {
    getVenues();
    if (!data) return;
    form.setFieldsValue({
      ...data,
      startDate: moment(data.startDate),
      endDate: moment(data.endDate)

    });
    //  console.log(data, "data>>>>>>>>....")
    setStartDate(data.startDate)
    setEndDate(data.endDate)
    setImage(data.image);
  }, [])

  const onCreate = (values) => {
    // setLoading(true);

    const payload = {
      ...values,
      startDate: startDate,
      endDate: endDate
    };
    console.log(payload);
    request({
      url: `${data ? path + "?id=" + data._id : path}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };
  //   const onFormValuesChange = (changedValues, allValues) => {
  //     setFormData(allValues);
  //   };

  function onChange(date, dateString) {
    setStartDate(dateString)
  }
  function onChangeEnd(date, dateString) {
    setEndDate(dateString)
  }

  return (
    <Modal
      width={800}
      open={show}
      title={`${data ? lang("Update") + " " + sectionName : lang("Add") + " " + lang("New") + " " + sectionName}`}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={
        <>
          <CloseOutlined style={{ marginRight: '8px' }} />
          {lang("Cancel")}
        </>
      }
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
        icon: < CheckOutlined />,
      }}
    >
      <Form id="create" form={form} initialValues={{}} onFinish={onCreate} onValuesChange={onCheckboxChange} layout="vertical">
        <Row gutter={[24, 16]}>
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Discount Code"
              name="discountCode"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the code!" },
                { max: 200, message: "Code should not contain more then 200 characters!" },
                { min: 2, message: "Code should contain at least 2 characters!" },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Discount Code" />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Discount Percentage"
              name="discountPercentage"
              normalize={value => value.replace(/[^\d]/g, '')}  // Remove non-numeric characters
              rules={[
                { required: true, message: "Enter percentage!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || /^[0-9]*$/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Only numbers are allowed!'));
                  },
                }),
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Discount Percentage" />


            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="No. of User"
              name="noOfUser"
            >
              <Input autoComplete="off" placeholder="Enter number of user" />

            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Ticketed Events"
              name="eventTickets"
            >
              <Select
                style={{
                  width: '100%',
                }}
                placeholder="Select Venue"
                onChange={(value) => form.setFieldValue("venue_id", value)}
                options={events.map(v => {
                  return { label: v?.name, value: v?._id }
                })}
              // options={[{ value: "India", label: "India" }, { value: "Canada", label: "Canada" }, { value: "USA", label: "USA" }]}
              />

            </Form.Item>
          </Col>
      
          <Col span={24} sm={12}>
            <Form.Item
              label="Discount Start Date"
              name="startDate"
            >
              <DatePicker format="YYYY-MM-DD"
                onChange={(value) => setStartDate(value)} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              label="Discount End Date"
              name="endDate"
            >

              <DatePicker 
              format="YYYY-MM-DD" 
              onChange={(value) => setEndDate(value)} 

              />            
              </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal >
  )
};

export default AddForm;